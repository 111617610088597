import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Modal, Select } from 'antd';
import { ISpecialtiesApproaches } from 'types/helloKlarity';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import useGetSpecialtiesAndConditions from 'hooks/useGetSpecialtiesAndConditions';
import { ISpecialtiesOption, ISpecialtiesOptions } from 'types/applicationForm';
import { EditModuleEnum } from 'types/practiceFront';

interface IProps {
    specialtyHighlightList: string[];
    initFormValue: {
        specialtyList?: string[];
        conditionTreatedList?: string[];
    },
    onSubmit?: (data: any, field: EditModuleEnum, callback?: () => void) => void;
}

const SpecificSpecNConditions = ({
    specialtyHighlightList,
    initFormValue,
    onSubmit,
}: IProps) => {
    const [specialtiesOptions] = useGetSpecialtiesAndConditions({ isOps: true });
    // const {
    //     specialtyList = [],
    //     // conditionTreatedList = [],
    // } = initFormValue || {};
    const specialtyList = useMemo(() => (initFormValue?.specialtyList ? initFormValue.specialtyList.filter((item) => !!item) : []), [initFormValue]);
    const conditionTreatedList = useMemo(() => (initFormValue?.conditionTreatedList ? initFormValue.conditionTreatedList.filter((item) => !!item) : []), [initFormValue]);

    const [highlights, setHighlights] = useState<string[]>([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [conditionOptions, setConditionOptions] = useState<ISpecialtiesOptions>([]);
    const [selectedCondition, setSelectedCondition] = useState<string[]>([]);

    useEffect(() => {
        if (specialtyHighlightList && specialtyHighlightList.length > 0) {
            setHighlights(specialtyHighlightList);
        }
    }, [specialtyHighlightList]);

    useEffect(() => {
        if (conditionTreatedList && conditionTreatedList.length > 0) {
            setSelectedCondition(conditionTreatedList);
        }
    }, [conditionTreatedList]);

    const handleSetConditionOptions = useCallback((specialties: string[] = []) => {
        const conditionOpts = specialtiesOptions
            .filter((option) => {
                const { value } = option;
                return !!specialties?.includes(value);
            })
            .reduce((r:ISpecialtiesOptions, c: ISpecialtiesOption) => {
                const { children = [] } = c;

                return r.concat(children);
            }, []);

        setConditionOptions(uniqWith(conditionOpts, isEqual));
    }, [specialtiesOptions]);

    useEffect(() => {
        handleSetConditionOptions(specialtyList);
    }, [handleSetConditionOptions, specialtyList]);

    const handleHighlight = useCallback((v: string) => {
        const newSet = new Set(highlights);
        const hasHighlight = newSet.has(v);

        if (hasHighlight) {
            newSet.delete(v);
        } else {
            newSet.add(v);
        }

        setHighlights(Array.from(newSet));
    }, [highlights]);

    const handleSave = useCallback(() => {
        onSubmit?.({
            specialty: specialtyList,
            specialtyHighlight: highlights.filter((item) => !!item),
            conditionTreated: selectedCondition.filter((item) => !!item),
        }, EditModuleEnum.SPECIFIC_SPECIALTIES, () => {
            setEditModalVisible(false);
        });
    }, [highlights, onSubmit, selectedCondition, specialtyList]);

    return (
        <div className={s.wrap}>
            <Modal
                title="Edit Specialties & Conditions"
                open={editModalVisible}
                onCancel={() => setEditModalVisible(false)}
                okText="Save changes"
                onOk={handleSave}
                destroyOnClose
            >
                <div className={s.modalContent}>
                    <div className={s.itemWrap}>
                        <div className={s.graylabel}>Specialties</div>
                        <div className={s.conditionBox}>
                            {
                                specialtyList && specialtyList.length > 0 ?
                                    specialtyList?.map((item, i) => {
                                        const highlight = highlights.includes(item);
                                        return (
                                            <div className={`${s.conditionTag} ${highlight ? s.highlight : ''}`} key={i} onClick={() => handleHighlight(item)}>{item}</div>
                                        );
                                    }) :
                                    <div className={s.noncontent}>Nothing here yet</div>
                            }
                        </div>
                    </div>
                    <div className={s.itemWrap}>
                        <div className={`${s.label} ${s.required}`}>Conditions treated</div>
                        <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            value={selectedCondition}
                            options={conditionOptions}
                            onChange={(value) => {
                                setSelectedCondition(value);
                            }}
                        />
                    </div>
                    {/* <div className={s.itemWrap}>
                        <div className={`${s.label}`}>Related conditions you may want to add:</div>
                        <div className={s.tags}>
                            {
                                conditionOptions.map((item, i) => <div className={s.tag} key={i}>{item.label}</div>)
                            }
                        </div>
                    </div> */}
                </div>
            </Modal>
            <div className={s.header}>
                <div className={s.title}>Specialties & Conditions</div>
                <div className={s.btns}>
                    <Button onClick={() => setEditModalVisible(true)}>Edit</Button>
                </div>
            </div>
            <div className={s.content}>
                <div className={s.row}>
                    <div
                        className={s.displayItem}
                    >
                        <div className={`${s.label} ${s.required}`}>Specialties</div>
                        <div className={s.value}>
                            {
                                specialtyList && specialtyList.length > 0 ?
                                    specialtyList?.map((item, i) => {
                                        const highlight = highlights.includes(item);
                                        return (
                                            <div className={`${s.conditionTag} ${highlight ? s.highlight : ''}`} key={i}>{item}</div>
                                        );
                                    }) :
                                    <div className={s.noncontent}>Nothing here yet</div>
                            }
                        </div>
                    </div>
                </div>
                <div className={s.row}>
                    <div
                        className={s.displayItem}
                    >
                        <div className={`${s.label} ${s.required}`}>Conditions treated</div>
                        <div className={s.value}>
                            {
                                conditionTreatedList && conditionTreatedList.length > 0 ?
                                    conditionTreatedList?.map((item, i) => <div className={s.conditionTag} key={i}>{item}</div>) :
                                    <div className={s.noncontent}>Nothing here yet</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecificSpecNConditions;
