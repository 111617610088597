// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__toastContainer--HP7li {
  position: fixed;
  bottom: 6vh;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
}
.s-module__toast--phy82 {
  min-width: 443px;
  background-color: #333333;
  color: white;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: s-module__slideUp--kBusX 0.3s ease-out;
}
.s-module__message--WZAVH {
  font-size: 16px;
  line-height: 24px;
}
.s-module__closeButton--Lj1_n {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: opacity 0.2s;
}
.s-module__closeButton--Lj1_n:hover {
  opacity: 1;
}
@keyframes s-module__slideUp--kBusX {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SuccessToast/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,OAAA;EACA,QAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAEA;EACI,gBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,wCAAA;EACA,iDAAA;AAAJ;AAGA;EACI,eAAA;EACA,iBAAA;AADJ;AAIA;EACI,gBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,wBAAA;AAFJ;AAII;EACI,UAAA;AAFR;AAMA;EACI;IACI,2BAAA;IACA,UAAA;EAJN;EAME;IACI,wBAAA;IACA,UAAA;EAJN;AACF","sourcesContent":[".toastContainer {\n    position: fixed;\n    bottom: 6vh;\n    left: 0;\n    right: 0;\n    z-index: 1000;\n    display: flex;\n    justify-content: center;\n}\n\n.toast {\n    min-width: 443px;\n    background-color: #333333;\n    color: white;\n    padding: 16px 24px;\n    border-radius: 8px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 16px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    animation: slideUp 0.3s ease-out;\n}\n\n.message {\n    font-size: 16px;\n    line-height: 24px;\n}\n\n.closeButton {\n    background: none;\n    border: none;\n    padding: 4px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    opacity: 0.8;\n    transition: opacity 0.2s;\n\n    &:hover {\n        opacity: 1;\n    }\n}\n\n@keyframes slideUp {\n    from {\n        transform: translateY(100%);\n        opacity: 0;\n    }\n    to {\n        transform: translateY(0);\n        opacity: 1;\n    }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toastContainer": `s-module__toastContainer--HP7li`,
	"toast": `s-module__toast--phy82`,
	"slideUp": `s-module__slideUp--kBusX`,
	"message": `s-module__message--WZAVH`,
	"closeButton": `s-module__closeButton--Lj1_n`
};
export default ___CSS_LOADER_EXPORT___;
