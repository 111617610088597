import React from 'react';
import s from './s.module.less';

interface SuccessToastProps {
    visible: boolean;
    message: string;
    onClose: () => void;
}

const CloseIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 4L4 12M4 4L12 12"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const SuccessToast: React.FC<SuccessToastProps> = ({ visible, message, onClose }) => {
    if (!visible) return null;

    return (
        <div className={s.toastContainer}>
            <div className={s.toast}>
                <span className={s.message}>{message}</span>
                <button
                    className={s.closeButton}
                    onClick={onClose}
                    aria-label="Close"
                    type="button"
                >
                    <CloseIcon />
                </button>
            </div>
        </div>
    );
};

export default SuccessToast;
