import React, { useState } from 'react';
import { Radio, Button, Tooltip } from 'antd';
import s from './s.module.less';

interface Step {
    title: string;
    description: React.ReactNode;
    status: 'completed' | 'current' | 'pending';
    index?: number;
    tip?: React.ReactNode;
}

interface ReviewFlowStepsProps {
    onNext?: (index: number, selectedValue?: number) => void;
    handleSubmitMinimalSurveyScore?: () => void;
    setSurveyJourneyIndex: (index: number) => void;
    surveyJourneyIndex: number;
    hasListedChannel?: boolean;
}

const CheckIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#00816B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3661 8.23431C17.6785 8.54673 17.6785 9.05327 17.3661 9.36569L10.9661 15.7657C10.6537 16.0781 10.1471 16.0781 9.83471 15.7657L6.63471 12.5657C6.32229 12.2533 6.32229 11.7467 6.63471 11.4343C6.94712 11.1219 7.45366 11.1219 7.76608 11.4343L10.4004 14.0686L16.2347 8.23431C16.5471 7.9219 17.0537 7.9219 17.3661 8.23431Z" fill="white" />
    </svg>
);

const ReviewFlowSteps: React.FC<ReviewFlowStepsProps> = ({
    onNext,
    handleSubmitMinimalSurveyScore,
    setSurveyJourneyIndex,
    surveyJourneyIndex,
    hasListedChannel,
}) => {
    const [selectedValue, setSelectedValue] = useState<number>(4);

    const getStepStatus = (index: number) => {
        if (index < surveyJourneyIndex) return 'completed';
        if (index === surveyJourneyIndex) return 'current';
        return 'pending';
    };

    const steps: Step[] = [
        {
            title: 'Survey invitation',
            description: <p className={s.description}>Patients receive an email inviting them to provide feedback on their experience with you.</p>,
            status: getStepStatus(0),
        },
        {
            title: 'Positive feedback (4 stars or above)',
            description: <p className={s.description}>Patients rate their experience and share feedback about their recent visit.</p>,
            status: getStepStatus(1),
        },
        {
            title: 'Invitation to leave a public review',
            description: (
                <p className={s.description}>If a patient gives a positive rating, they will be invited to leave a public review on selected channels.
                    <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#fff" title={<div className={s.tipText}>These channels are selected based on your channel listing with Kiwi Health and the reviews&apos; impact to your online presences.</div>}>
                        <span style={{ color: '#00816B' }}>How is the channel selected?</span>
                    </Tooltip>
                </p>
            ),
            status: getStepStatus(2),
            tip: hasListedChannel ? null : (
                <div className={s.tips}>
                    <div className={s.tipIcon} />
                    <div className={s.tipContent}>
                        <div className={s.tipDesc}>It appears that you don’t have any channels currently set up to receive patient reviews. Let’s change that! Explore the “Channel Store”, list yourself on channels that align with your practice and start building your reputation today.</div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Negative ratings',
            description: <p className={s.description}>If a patient gives a rating below 3 stars, they can leave private feedback and choose whether to be contacted for follow-ups. They will not be invited to leave a public review.</p>,
            status: getStepStatus(3),
        },
    ];

    const handleNext = (step: Step) => {
        if (step.status === 'current') {
            let selectedValueParam;
            if (step.index === 2) {
                selectedValueParam = selectedValue;
            } else if (step.index === 3) {
                handleSubmitMinimalSurveyScore?.();
            }

            if (surveyJourneyIndex < steps.length - 1) {
                const newStep = surveyJourneyIndex + 1;
                // setCurrentStepIndex(newStep);
                setSurveyJourneyIndex(newStep);
                onNext?.(newStep, selectedValueParam);
            }
        }
    };

    return (
        <div className={s.container}>
            <div className={s.timeline}>
                {steps.map((step, index) => (
                    <div key={index} className={s.step}>
                        <div className={s.stepPoint}>
                            <div className={`${s.point} ${s[step.status]}`}>
                                {step.status === 'completed' && <CheckIcon />}
                                {step.status === 'current' && <div className={s.currentDot} />}
                            </div>
                            {index < steps.length - 1 && (
                                <div className={`${s.line} ${
                                    step.status === 'completed' || step.status === 'current'
                                        ? s.activeLine
                                        : ''
                                }`}
                                />
                            )}
                        </div>
                        <div className={s.content}>
                            <h3 className={`${s.title} ${step.status === 'current' ? s.selected : ''}`}>{step.title}</h3>
                            {step.description}
                            {step.status === 'current' && index === 2 && (
                                <>
                                    {step.tip}
                                    <div className={s.selection}>
                                        <div className={s.selectionHeader}>
                                            <span>Please select who you want to invite to write reviews on your public channels:</span>
                                        </div>
                                        <Radio.Group
                                            value={selectedValue}
                                            onChange={(e) => setSelectedValue(e.target.value)}
                                        >
                                            <Radio value={4}>High satisfaction patients (4 stars or above)</Radio>
                                            <Radio value={5}>Outstanding satisfaction patients (5 stars only)</Radio>
                                        </Radio.Group>
                                    </div>
                                </>
                            )}
                            {step.status === 'current' && (
                                <Button
                                    type="primary"
                                    onClick={() => handleNext({
                                        ...step,
                                        index,
                                    })}
                                    className={s.nextButton}
                                >
                                    Next
                                </Button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ReviewFlowSteps;
