import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Form, Input, Spin, message } from 'antd';
import { IUpdatePasswordFormData, IUpdateTelFormData } from 'types/provider';
import { updateProviderNotificationConfig, updateProviderPassword } from 'api/provider';
import ProviderStore from 'store/Provider';
import { sendForget } from 'api/public';
import { hasLowerCase, hasNumber, hasSpecialCharacter, hasUpperCase, isValidUSPhoneV2 } from 'utils/form';
import { PasswordError } from 'types/common';
import useProspectSettingInfo from 'hooks/useProspectSettingInfo';
import useProviderNotificationConfig from 'hooks/useProviderNotificationConfig';
import InfoNote from 'components/InfoNote';
import { REQUIRED_FIELD } from 'constants/common';

const MyAccount = () => {
    const [getUser] = ProviderStore.useStore();
    const [info] = useProspectSettingInfo();
    const [notifyInfo, loadingNotify, refetch] = useProviderNotificationConfig();
    const user = getUser('data');

    const [formInstance] = Form.useForm<IUpdatePasswordFormData>();
    const [telFormInstance] = Form.useForm<IUpdateTelFormData>();
    const [edit, setEdit] = useState(false);
    const [editPhone, setEditPhone] = useState(false);
    const [phoneValue, setPhoneValue] = useState('');
    const [showForgetTip, setShowForgetTip] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasSentCheckEmail, setHasSentCheckEmail] = useState(false);
    const [resendButtonDisabled, setResendButtonDisabled] = useState(false);
    const [psw, setPsw] = useState('');
    const [pswError, setPswError] = useState<PasswordError[]>([]);
    const showPwsError = psw && pswError.length > 0;

    const [countDown, setCountDown] = useState(0);
    const timer = useRef(60);
    const SI = useRef<ReturnType<typeof setInterval> | null>(null);

    const tel = notifyInfo?.tel;
    const isUseProfileTel = !notifyInfo?.tel || (notifyInfo?.tel && notifyInfo.notificationPhoneNumber && notifyInfo?.tel === notifyInfo.notificationPhoneNumber)

    useEffect(() => {
        if (hasSentCheckEmail && countDown > 0) {
            SI.current = setInterval(() => {
                setCountDown(countDown - 1);
            }, 1000);
            setResendButtonDisabled(true);
        } else if (SI.current) {
            setResendButtonDisabled(false);
            clearInterval(SI.current);
        }

        return () => {
            if (SI.current) {
                clearInterval(SI.current);
            }
        };
    }, [countDown, hasSentCheckEmail]);

    const handleFormFinish = useCallback(() => {
        formInstance.validateFields().then(async (values: IUpdatePasswordFormData) => {
            if (user && user.email && !showPwsError) {
                try {
                    const res = await updateProviderPassword({
                        ...values,
                        email: user.email,
                    });
                    if (res && res.error) {
                        message.error(res.error);
                    } else {
                        setEdit(false);
                        setShowForgetTip(false);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }).catch((e) => {
            console.error(e);
        });
    }, [formInstance, showPwsError, user]);

    const sendForgetFun = useCallback(async () => {
        if (user && user.email && !resendButtonDisabled) {
            setLoading(true);
            const result = await sendForget({
                email: user.email,
            });
            if (!result.error) {
                setShowForgetTip(true);
                setCountDown(timer.current);
            } else {
                message.error(result.error);
            }
            setLoading(false);
        }
    }, [resendButtonDisabled, user]);

    const sendButtonRender = useMemo(() => {
        return hasSentCheckEmail ?
            <Button disabled={resendButtonDisabled} className={s.resetBtn} onClick={sendForgetFun}>{resendButtonDisabled ? `Resend ${countDown}` : 'Resend'}</Button> :
            <div
                className={s.forget}
                onClick={() => {
                    sendForgetFun();
                    setHasSentCheckEmail(true);
                }}
            >Forgot password?
            </div>;
    }, [countDown, hasSentCheckEmail, resendButtonDisabled, sendForgetFun]);

    useEffect(() => {
        telFormInstance.setFieldsValue({
            tel,
        })
    }, [tel]);

    const handleSaveTel = async () => {
        telFormInstance.validateFields().then(async (values: IUpdateTelFormData) => {
            const tel = values.tel;
            if (!isValidUSPhoneV2(tel)) {
                message.error('Invalid phone number');
                return;
            }
            try {
                const res = await updateProviderNotificationConfig(null, null, values.tel);
                if (res && res.error) {
                    message.error(res.error);
                } else {
                    refetch();
                    setEditPhone(false);
                }
            } catch (e) {
                console.error(e);
            }
        }).catch((e) => {
            console.error(e);
        });
    }

    if (editPhone) {
        return (
            <Spin spinning={loading}>
                <div className={s.wrap}>
                    <div className={s.telLabel}>Phone numbers:</div>
                    <Form
                        form={telFormInstance}
                        name="form"
                        autoComplete="off"
                        layout="vertical"
                        onFinish={handleFormFinish}
                        scrollToFirstError
                    >
                        <Form.Item
                            label="This is your personal number"
                            name="tel"
                            style={{ width: '430px', marginBottom: '4px' }}
                            rules={[
                                { required: true, message: REQUIRED_FIELD },
                            ]}
                        >
                            <Input value={phoneValue} maxLength={18} onChange={(e) => setPhoneValue(e.target.value)} />
                        </Form.Item>
                    </Form>
                    <div className={s.acWrap}>
                        <Button style={{ marginRight: '16px' }} type='primary' onClick={handleSaveTel}>Save</Button>
                        <Button onClick={() => setEditPhone(false)}>Cancel</Button>
                    </div>
                </div>
            </Spin >
        )
    }

    return (
        <Spin spinning={loading}>
            <div className={s.wrap}>
                {
                    edit ?
                        <Form
                            form={formInstance}
                            name="form"
                            autoComplete="off"
                            layout="vertical"
                            onFinish={handleFormFinish}
                            scrollToFirstError
                        >
                            <Form.Item
                                label="Old password"
                                name="oldPassword"
                                style={{ width: '430px', marginBottom: '4px' }}
                                rules={[
                                    { required: true, message: 'old password is required' },
                                ]}
                            >
                                <Input type="password" />
                            </Form.Item>
                            {
                                showForgetTip &&
                                <div className={s.forgetTip}>
                                    <div className={s.tipIcon} />
                                    <div className={s.tipText}>An email with instructions to reset your password has been sent. If you don't receive the email, please click Resent.</div>
                                </div>
                            }
                            {
                                sendButtonRender
                            }
                            <Form.Item
                                label="New password"
                                name="password"
                                style={{ width: '430px' }}
                                rules={[
                                    { required: true, message: 'new password is required' },
                                ]}
                            >
                                <Input.Password
                                    value={psw}
                                    status={showPwsError ? 'error' : undefined}
                                    onChange={(e) => {
                                        const val = e.target.value;
                                        const error: PasswordError[] = [];
                                        if (!hasLowerCase(val)) {
                                            error.push(PasswordError.LOWERCASE);
                                        }
                                        if (!hasNumber(val)) {
                                            error.push(PasswordError.NUMBER);
                                        }
                                        if (!hasSpecialCharacter(val)) {
                                            error.push(PasswordError.SPECIAL);
                                        }
                                        if (!hasUpperCase(val)) {
                                            error.push(PasswordError.UPPERCASE);
                                        }
                                        if (val.length < 8) {
                                            error.push(PasswordError.MINIMUM);
                                        }
                                        setPswError(error);
                                        setPsw(e.target.value);
                                    }}
                                    size="large"
                                    style={{ marginBottom: '4px' }}
                                />
                            </Form.Item>
                            <div className={showPwsError ? `${s.pswError} ${s.pswErrorShow}` : s.pswError}>
                                <div><span className={pswError.includes(PasswordError.LOWERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One lowercase character</div>
                                <div><span className={pswError.includes(PasswordError.SPECIAL) ? `${s.icon} ${s.iconError}` : s.icon} />One special character</div>
                                <div><span className={pswError.includes(PasswordError.UPPERCASE) ? `${s.icon} ${s.iconError}` : s.icon} />One uppercase character</div>
                                <div><span className={pswError.includes(PasswordError.MINIMUM) ? `${s.icon} ${s.iconError}` : s.icon} />8 characters minimum</div>
                                <div><span className={pswError.includes(PasswordError.NUMBER) ? `${s.icon} ${s.iconError}` : s.icon} />One number</div>
                            </div>
                            <Form.Item
                                label="Confirm password"
                                name="confirmPassword"
                                style={{ width: '430px' }}
                                rules={
                                    [
                                        {
                                            validator: (rules, value) => {
                                                const newPwd = formInstance.getFieldValue('password');

                                                if (value !== newPwd) {
                                                    return Promise.reject(
                                                        new Error('The two passwords are entered differently'),
                                                    );
                                                }

                                                return Promise.resolve(true);
                                            },
                                        },
                                    ]
                                }
                            >
                                <Input type="password" />
                            </Form.Item>
                            <Form.Item>
                                <Button className={s.formBtn} type="primary" htmlType="submit">Save</Button>
                                <Button className={s.formBtn} onClick={() => setEdit(false)}>Cancel</Button>
                            </Form.Item>
                        </Form> :
                        <>
                            <div className={s.item}>
                                <div className={s.Label}>Account email</div>
                                <div className={s.value}>{user?.email || ''}</div>
                            </div>
                            <div className={s.item}>
                                <div className={s.Label}>Password</div>
                                <div className={s.value}>**********<a className={s.action} onClick={() => setEdit(true)}>Change</a></div>
                            </div>
                            <div className={s.item}>
                                <div className={s.Label}>Phone number</div>
                                <div className={s.value}>{tel} <a className={s.action} onClick={() => setEditPhone(true)}>Change</a></div>
                                {
                                    isUseProfileTel &&
                                    <div className={s.tip}>The number is now used for prospect notifications. You can configure the settings in the prospect section</div>
                                }
                            </div>
                        </>
                }
            </div >
        </Spin >
    );
};

export default MyAccount;
